import React, { useState, useEffect } from 'react';
import { HeadingTag, ModuleBase, ModalPortal, Button } from '@/components';
import Mapbox from './Mapbox';
import { useTranslation } from 'next-i18next';
import HotelsList from './HotelsList';
import PlusIcon from '@/assets/plusIcon.svg';
import Close from '@/assets/close.svg';
import classNames from 'classnames';
import { isColorDark, themeFromBg } from '@/utils';

const MapFullBleedModule = ({ data }) => {
  const [listMode, setListMode] = useState(true);
  const [isWebgl2Supported, setWebgl2Supported] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const { t } = useTranslation('common');

  const [filters, setFilters] = useState([
    { filterKey: 'isCoastalTown', active: false },
    { filterKey: 'isBeachfrontLocation', active: false },
    { filterKey: 'isInTheCity', active: false },
    { filterKey: 'isSpa', active: false },
    { filterKey: 'isInTheCountry', active: false },
  ]);

  const [pois, setPois] = useState(data.items);

  useEffect(() => {
    if (document.createElement('canvas').getContext('webgl2')) {
      setWebgl2Supported(true);
      setListMode(false);
    }
  }, []);

  useEffect(() => {
    if (filters.find((fi) => fi.active)) {
      const activeFilters = [];

      filters.forEach((fi) => {
        if (fi.active) {
          activeFilters.push(fi.filterKey);
        }
      });

      const newPois = data.items.filter((poi) => {
        let sr = false;
        activeFilters.forEach((activeFilter) => {
          if (poi.props[activeFilter]) {
            sr = true;
          }
        });
        return sr;
      });
      setPois(newPois);
    } else {
      setPois(data.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const toggleFilter = (filter) => {
    setFilters(
      filters.map((fi) => ({
        ...fi,
        active: fi.filterKey === filter.filterKey ? !fi.active : fi.active,
      })),
    );
  };

  const clearFilters = () => {
    setFilters(
      filters.map((fi) => ({
        ...fi,
        active: false,
      })),
    );
  };

  const isDark = isColorDark(data.backgroundColour);
  const theme = themeFromBg(isColorDark(data.backgroundColour));

  return (
    <ModuleBase data={data} className="flex flex-col">
      <HeadingTag
        data={{ heading: data.title }}
        className={classNames(
          'container font-ivy-ora text-title-l not-italic font-normal leading-60 -tracking-normal text-center',
          isDark ? 'text-secondary-coolsand' : 'text-primary',
        )}
      />
      <div
        className={classNames(
          'container mt-10 py-6 flex flex-col gap-4',
          isDark ? 'text-secondary-coolsand' : 'text-primary',
        )}
      >
        <div className="flex justify-between">
          <div className="flex gap-4 items-center">
            {data.showFilters && (
              <div
                className="flex lg:hidden items-center"
                aria-label={t('general.$filters')}
                onClick={() => setFilterOpen(true)}
              >
                <div className="font-cera text-tag-m not-italic font-normal leading-24 mr-2">
                  {t('general.$filters')}
                </div>
                <PlusIcon className={classNames(isDark ? 'fill-secondary-coolsand' : 'fill-primary')} />
              </div>
            )}

            {data.showFilters && (
              <div className="hidden lg:block font-cera text-tag-m not-italic font-normal leading-24">
                {t('map.$filterby')}
              </div>
            )}

            {data.showFilters && (
              <div className="hidden lg:flex flex-wrap gap-4">
                {filters.map((filter) => (
                  <div
                    key={filter.filterKey}
                    className={classNames(
                      'font-cera text-tag-m not-italic font-normal leading-24 px-4 py-3 border rounded-full cursor-pointer transition w-fit',
                      isDark ? 'border-secondary-coolsand/50' : 'border-primary/50',
                      filter.active
                        ? isDark
                          ? 'text-primary bg-secondary-coolsand'
                          : 'text-white bg-primary'
                        : isDark
                          ? 'text-secondary-coolsand'
                          : 'text-primary',
                    )}
                    onClick={() => toggleFilter(filter)}
                    aria-label={t('map.$' + filter.filterKey)}
                  >
                    {t('map.$' + filter.filterKey)}
                  </div>
                ))}
              </div>
            )}

            {data.showFilters && (
              <div
                className="hidden lg:flex font-cera text-tag-m not-italic font-normal leading-24 underline cursor-pointer"
                aria-label={t('map.$clearall')}
                onClick={clearFilters}
              >
                {t('general.$clearall')}
              </div>
            )}
          </div>

          {data.allowListView && isWebgl2Supported && (
            <div className="flex items-center min-w-max">
              <label className="inline-flex items-center cursor-pointer">
                <span className="font-cera text-tag-m not-italic font-normal leading-24 mr-4">
                  {t('general.$showlistview')}
                </span>
                <input type="checkbox" className="sr-only peer" onChange={(e) => setListMode(e.target.checked)} />
                <div
                  className={classNames(
                    "relative w-7 h-4 border rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[2px] after:start-[3px] after:rounded-full after:h-2.5 after:w-2.5 after:transition-all",
                    isDark
                      ? 'border-secondary-coolsand after:bg-secondary-coolsand'
                      : 'border-primary after:bg-primary',
                  )}
                ></div>
              </label>
            </div>
          )}
        </div>

        {data.showFilters && (
          <div className="font-cera text-tag-m not-italic font-normal leading-24">
            {t('map.$showingXofX', { showing: pois.length, total: data.items.length })}
          </div>
        )}
      </div>

      <ModalPortal aria-modal="true" role="dialog">
        <div
          className={classNames(
            'fixed flex flex-col justify-between top-0 left-0 z-40 w-screen h-dvh duration-500 px-6 py-10',
            {
              '-translate-x-full': !filterOpen,
            },
          )}
          style={{ backgroundColor: data.backgroundColour }}
        >
          <div className="flex justify-betwee">
            <div
              className={classNames(
                isDark ? 'text-secondary-coolsand' : 'text-primary',
                'color-from-bg font-cera text-paragraph-l not-italic font-normal leading-24 -tracking-paragraphReg',
              )}
            >
              {t('general.$filters')}
            </div>
            <div className="-mr-2 -mt-2 p-2" onClick={() => setFilterOpen(false)}>
              <Close className={classNames(isDark ? 'fill-secondary-coolsand' : 'fill-primary')} />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {filters.map((filter) => (
              <div
                key={filter.filterKey}
                className={classNames(
                  'font-cera text-tag-m not-italic font-normal leading-24 px-4 py-3  rounded-full cursor-pointer transition w-fit',
                  filter.active ? 'text-white bg-primary' : 'text-primary',
                  isDark
                    ? 'text-secondary-coolsand border border-secondary-coolsand/50'
                    : 'text-primary border border-primary/50',
                )}
                onClick={() => toggleFilter(filter)}
                aria-label={t('map.$' + filter.filterKey)}
              >
                {t('map.$' + filter.filterKey)}
              </div>
            ))}
          </div>
          <div className="flex gap-2 w-full">
            <button
              className={`btn primary w-full ${isDark ? 'light' : 'dark'}`}
              onClick={() => {
                clearFilters();
                setFilterOpen(false);
              }}
            >
              <span className={`${isDark ? 'text-secondary-coolsand' : 'text-primary'}`}>{t('general.$clearall')}</span>
            </button>

            <button
              className={`btn secondary w-full ${data.backgroundColour === '#081B36' ? '!bg-secondary-coolsand !text-primary' : '!bg-primary'}`}
              onClick={() => setFilterOpen(false)}
            >
              <span
                className={`${data.backgroundColour === '#081B36' ? ' !text-primary' : '!text-secondary-coolsand'}`}
              >
                {t('general.$apply')}
              </span>
            </button>
          </div>
        </div>
      </ModalPortal>
      {listMode ? (
        <HotelsList data={data} pois={pois} isDark={isDark} theme={theme} />
      ) : (
        <Mapbox data={data} pois={pois} />
      )}
      <Button link={data.ctaLink} type="primary" theme={theme} className="m-auto mt-8 lg:mt-10" />
    </ModuleBase>
  );
};

export default MapFullBleedModule;
