/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { ModuleBase, SearchFilters, Pagination } from '@/components';
import { getSearchFilters } from '@/utils';
import { selectPageId } from '@/store/index';
import GridCard from '@/components/cards/GridCard';
import tailwindConfig from '@/tailwind.config';

const CardListingGridModule = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const router = useRouter();
  const fetchController = useRef(null);
  const queryData = useRef({ filters: [], page: 1 });
  const pageId = useSelector(selectPageId);
  const cardContainerRef = useRef(null);

  useEffect(() => {
    let newFilters = { filters: getSearchFilters(router), page: Number(router.query.page || 1) };
    if (data?.filtersAndCards?.cards && JSON.stringify(newFilters) === JSON.stringify(queryData.current)) {
      setCards(data.filtersAndCards.cards);
      setTotalCount(data.filtersAndCards.totalCount);
    } else if (JSON.stringify(newFilters) !== JSON.stringify(queryData.current) && pageId) {
      queryData.current = newFilters;
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, data]);

  useEffect(() => {
    let newFilters = { filters: getSearchFilters(router), page: Number(router.query.page || 1) };
    if (JSON.stringify(newFilters) !== JSON.stringify(queryData.current) && pageId) {
      queryData.current = newFilters;
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, router.query]);

  const getData = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (fetchController.current) {
      fetchController.current.abort();
    }
    const controller = new AbortController();
    fetchController.current = controller;

    const reqData = {
      PageId: pageId,
      //Filters: filters.current.filters,
      Filters: queryData.current.filters.map((item) => ({ FieldName: 'tag', FieldGuids: item.FieldGuids })),
      PageSize: data.filtersAndCards.pageSize,
      PageNumber: queryData.current.page,
      CardsType: data.filtersAndCards.cardType,
      ancestorId: data.filtersAndCards.ancestorId,
      moduleGuid: data.filtersAndCards.moduleGuid,
      Featured: 0,
      totalCount: data.filtersAndCards.totalCount,
      totalPages: data.filtersAndCards.totalPages,
      listItemPageIds: data.filtersAndCards.listItemPageIds,
      firstCardIsHighlighted: data.filtersAndCards.firstCardIsHighlighted,
    };

    const isPreview = !!router.query.isPreview;

    const dataRes = await fetch(`${!isPreview ? '/api' : ''}/umbraco/api/ProductCard/GetCards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      signal: fetchController.current?.signal,
      body: JSON.stringify(reqData),
    }).catch(console.error);

    if (dataRes && dataRes.ok) {
      const data = await dataRes.json();
      setCards(data.cards);
      setTotalCount(data.totalCount);
    }

    await new Promise((resolve) => setTimeout(resolve, 100));
    setLoading(false);
  };

  const classByCardsPerRow = (cardsPerRow) => {
    switch (cardsPerRow) {
      case 1:
        return 'xxl:grid-cols-1';
      case 2:
        return 'xxl:grid-cols-2';
      case 3:
        return data.filtersAndCards.cardType === 'Article' ? 'xxl:grid-cols-3' : 'xl:grid-cols-3 xl:w-[1110px]';
      default:
        return 'xxl:grid-cols-3';
    }
  };

  const scrollToTop = () => {
    if (cardContainerRef?.current?.offsetTop) {
      let padding = 60;
      if (window.innerWidth > parseInt(tailwindConfig.theme.screens.lg, 10)) padding = 120;
      window.scrollTo({
        top: cardContainerRef.current.offsetTop - padding,
        behavior: 'smooth',
      });
    }
  };

  return (
    <ModuleBase data={data} className="py-20">
      <div
        className={`container ${data.filtersAndCards.cardType === 'Article' ? 'px-[15.5px] m-auto' : ''}  transition-opacity duration-500 ${loading ? 'opacity-0' : 'opacity-100'}`}
      >
        <SearchFilters filters={data.filtersAndCards?.filter} queryMode />

        <div
          className="necessary-classes hidden h-[800px] h-[600px] 
            md:h-[817px] md:h-[617px]
            xxl:h-[774px] xxl:h-[574px]
            xxl:min-h-[873px] xxl:min-h-[637px]
            h-[435px] h-[235px]
            xxl:h-[498px] xxl:h-[298px]
            xxl:h-[597px] xxl:h-[397px]
            xxl:h-[697px] xxl:h-[897px]
            md:h-[764px] md:h-[564px]
            xxl:!h-[700px] xxl:!h-[500px]"
        ></div>
        <div
          ref={cardContainerRef}
          className={`flex flex-col md:grid auto-rows-fr md:auto-rows-auto w-auto md:w-[724px] lg:w-[960px] xl:w-[1200px] xxl:w-[1356px] m-auto grid-cols-1 md:grid-cols-2 ${classByCardsPerRow(data.cardsPerRow)} ${data.filtersAndCards.cardType === 'Article' ? 'gap-4 md:!w-[625px] lg:!w-[932px] xxl:!w-full' : 'gap-10'} xxl:justify-between`}
        >
          {cards &&
            cards.map(
              (card) =>
                card && (
                  <GridCard
                    key={card.moduleId}
                    data={card}
                    cardsPerRow={data.cardsPerRow}
                    cardType={data.filtersAndCards.cardType}
                  />
                ),
            )}
        </div>
        <div className="mt-[88px]">
          <Pagination
            backgroundColour={data.backgroundColour}
            totalCount={data.filtersAndCards?.totalCount}
            pageSize={data.filtersAndCards?.pageSize}
            queryMode
            onChange={scrollToTop}
          />
        </div>
      </div>
    </ModuleBase>
  );
};
export default CardListingGridModule;
