import classNames from 'classnames';
import NavItem from './NavItem';
import { Link, ResponsiveImage } from '@/components';
import { useTranslation } from 'next-i18next';
import NavArrow from '@/assets/navArrow.svg';
import { useEffect, useRef, useState } from 'react';
import tailwindConfig from '@/tailwind.config';

const NavColumn = ({
  navItems,
  flyouHead,
  flyoutBottom,
  globalNavItems,
  mainNavTitle,
  allHotels,
  level,
  activeMenu,
  setActiveMenu,
  unsetActiveMenu,
  close,
  parentMenuName,
}) => {
  const lKey = `l${level}`;
  const _setActiveMenu = (index, subMenu) => {
    let newValue = {};
    newValue[`me${index}`] = subMenu;
    setActiveMenu(newValue);
  };
  const _setActiveMenuAllHotels = (index, subMenu) => {
    let newValue = {};
    newValue[`mah${index}`] = subMenu;
    setActiveMenu(newValue);
  };
  const _setActiveMenuGlobal = (index, subMenu) => {
    let newValue = {};
    newValue[`mg${index}`] = subMenu;
    setActiveMenu(newValue);
  };
  const _unsetActiveMenu = () => {
    unsetActiveMenu();
  };

  const { t } = useTranslation('common');
  const gridref = useRef(null);
  const [containerClass, setcontainerClass] = useState('');
  const [withClass, setWithClass] = useState('lg:w-[26%]');
  const [hasTwoColumn, setHasTwoColumn] = useState(false);
  const [isMenuTitleTwoLine, setIsMenuTitleTwoLine] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    if (level === 1) {
      let linkElementsSize = 0;
      navItems?.forEach((nav) => {
        if (nav.linkElements) {
          linkElementsSize += nav.linkElements.length + 2;
        }
        if (nav.navigationalTitle) {
          linkElementsSize++;
        }
        if (nav.ctaLink) {
          linkElementsSize++;
        }
      });
      linkElementsSize += flyoutBottom?.length * 2;
      if (
        window.innerWidth > parseInt(tailwindConfig.theme.screens.lg, 10) &&
        gridref.current.scrollHeight + 100 > gridref.current.clientHeight &&
        linkElementsSize > 15
      ) {
        setcontainerClass(
          `block columns-2 h-[calc(100svh-145px)] [&>div]:gap-0 [&>div>div]:lg:mb-9  [&>div>div]:xxxl:mb-10 ${screenHeight < 720 ? '[&>div>div]:lg:!mb-2' : ''}`,
        ); // TODO: column 2
        setWithClass('lg:w-1/2');
        setHasTwoColumn(true);
      } else {
        setcontainerClass('[&>.flex]:gap-10');
        setWithClass('lg:w-[26%]');
        setHasTwoColumn(false);
      }
    }
  }, [gridref, level, navItems, flyoutBottom, screenHeight]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const checkMenuTitleSize = () => {
      if (document.querySelector('.main-title')?.offsetHeight > 60) {
        setIsMenuTitleTwoLine(true);
      } else {
        setIsMenuTitleTwoLine(false);
      }

      setScreenHeight(window.innerHeight);
    };
    setInterval(() => checkMenuTitleSize(), 200);
    window.addEventListener('load', checkMenuTitleSize);
    window.addEventListener('resize', checkMenuTitleSize);
    setLoaded(true);
    return () => {
      window.removeEventListener('load', checkMenuTitleSize);
      window.removeEventListener('resize', checkMenuTitleSize);
    };
  }, []);

  return (
    <div
      className={classNames(
        'nav-col-' + level,
        'absolute top-0 w-full h-full transition-transform duration-700 ease-out origin-right overflow-hidden pointer-events-none',
        !(activeMenu && activeMenu[lKey]) && 'translate-x-full lg:-translate-x-full',
        level ? 'left-0 lg:left-1/4' : 'left-0',
        { 'z-0': level === 0, 'z-10 lg:-z-10': level === 1, 'z-20 lg:-z-20': level === 2 },
      )}
    >
      <div className="w-full h-full relative pointer-events-none">
        <div
          className={classNames(
            'menu-col h-full pointer-events-auto bg-secondary-warmsand outline outline-1 outline-[#00030F1A]',
            !parentMenuName
              ? mainNavTitle
                ? isMenuTitleTwoLine
                  ? 'pt-[148px]'
                  : 'pt-[128px]'
                : 'pt-[75px] lg:pt-[80px]'
              : mainNavTitle
                ? isMenuTitleTwoLine
                  ? 'pt-[226px] lg:pt-[71px]'
                  : 'pt-[206px] lg:pt-[71px]'
                : 'pt-[175px] lg:pt-[71px]',
            withClass,
          )}
        >
          {!parentMenuName && <div className="bg-primary/10 h-[1px] mx-10 lorem" />}

          {parentMenuName && (
            <>
              <div className={`${mainNavTitle ? 'mt-[-76px]' : 'mt-[-76px]'} lg:hidden`}>
                <div className="bg-primary/10 h-[1px] mx-10" />

                <button
                  className="pl-[40px] pr-[42px] lg:px-16 py-6 w-full lg:hidden cursor-pointer text-primary flex items-center justify-between text-xs not-italic font-medium leading-normal tracking-quote-reg-mobile uppercase font-montserrat"
                  onClick={() => setActiveMenu(null)}
                >
                  <NavArrow className="-rotate-180 fill-black mr-2" role="presentation" />
                  <span className="-mt-px">{t('general.$back')}</span>
                </button>
                <div className="bg-primary/10 h-[1px] mx-10 mb-2" />
              </div>
            </>
          )}

          <div
            ref={gridref}
            className={classNames(
              'overflow-y-auto flex flex-col justify-between h-full max-h-full',
              !parentMenuName && 'pt-0',
            )}
          >
            <div
              className={classNames(
                'menu-me-' + level,
                'flex flex-col gap-10 pl-[40px] pr-[42px]',
                !parentMenuName ? 'pt-6 pb-10' : '',
              )}
            >
              {flyouHead && (
                <Link className="mt-4 lg:mt-0 text-primary text-sm leading-6" link={flyouHead} onClick={close}>
                  {flyouHead.text}
                </Link>
              )}
              <div style={{ columnFill: 'auto' }} className={`${containerClass}`}>
                <div className="flex flex-col gap-4">
                  {navItems?.map((nav, index) => (
                    <div key={index}>
                      <NavItem
                        navItem={nav}
                        parentIndex={index}
                        level={level}
                        hasTwoColumn={hasTwoColumn}
                        activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`me${index}`] : {}) : {}}
                        setActiveMenu={(subMenu) => _setActiveMenu(index, subMenu)}
                        unsetActiveMenu={() => _unsetActiveMenu()}
                        close={close}
                        parentMenuName={parentMenuName}
                        mainNavTitle={mainNavTitle}
                      />
                    </div>
                  ))}
                  {flyoutBottom &&
                    flyoutBottom.length > 0 &&
                    flyoutBottom.map((flyout, index, row) => (
                      <div
                        className={`flex flex-col gap-4 pt-8 border-t border-primary/10 break-inside-avoid-column ${index === row.length - 1 ? 'pb-8' : ''}`}
                        key={index}
                      >
                        <div className="text-primary font-medium text-sm leading-6">{flyout.navigationalTitle}</div>
                        <div>
                          {flyout?.linkElements &&
                            flyout.linkElements.map((link, index) => (
                              <div key={index}>
                                <div>
                                  <Link
                                    className={`text-primary text-sm leading-6 w-fit xl:[&:hover+.image-block]:opacity-100`}
                                    key={index}
                                    link={link.link}
                                    onClick={close}
                                  >
                                    {link.link?.text}
                                  </Link>
                                  {loaded &&
                                    link.image &&
                                    window.innerWidth > parseInt(tailwindConfig.theme.screens.xl, 10) && (
                                      <div
                                        className={`image-block absolute w-1/4 h-full top-0 right-0 transition-opacity duration-500  z-[-1] opacity-0
                                      ${hasTwoColumn ? 'translate-x-[-100%]' : 'translate-x-[-200%]'}
                                    `}
                                      >
                                        <ResponsiveImage
                                          image={link.image}
                                          widths={{ xs: window.innerWidth / 4 }}
                                          heights={{ xs: window.innerHeight }}
                                        />
                                      </div>
                                    )}
                                </div>
                                {index !== row.length - 1 && <span className="comma">,&nbsp;</span>}
                              </div>
                            ))}
                        </div>
                        {flyout.ctaLink && (
                          <Link link={flyout.ctaLink} onClick={close}>
                            {flyout.ctaLink.text}
                          </Link>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {level === 0 && globalNavItems && (
              <div
                className={`${'menu-mah-' + level} flex flex-col gap-6 pl-[40px] pr-[40px] py-[40px] bg-primary [&>div>.link-element]:text-white [&>div>.link-element:hover]:text-white `}
              >
                {allHotels && (
                  <NavItem
                    navItem={allHotels}
                    level={0}
                    parentIndex={0}
                    hasTwoColumn={hasTwoColumn}
                    activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`mah0`] : {}) : {}}
                    setActiveMenu={(subMenu) => _setActiveMenuAllHotels(0, subMenu)}
                    unsetActiveMenu={() => _unsetActiveMenu()}
                    close={close}
                    parentMenuName={parentMenuName}
                    isGlobalNavItem={true}
                    mainNavTitle={mainNavTitle}
                  />
                )}
                {globalNavItems?.map((nav, index) => (
                  <NavItem
                    navItem={nav}
                    key={index}
                    level={level}
                    parentIndex={index}
                    hasTwoColumn={hasTwoColumn}
                    activeMenu={activeMenu ? (activeMenu[lKey] ? activeMenu[lKey][`mg${index}`] : {}) : {}}
                    setActiveMenu={(subMenu) => _setActiveMenuGlobal(index, subMenu)}
                    unsetActiveMenu={() => _unsetActiveMenu()}
                    close={close}
                    parentMenuName={parentMenuName}
                    dividerTop={level === 0 && allHotels && index === 0}
                    isGlobalNavItem={true}
                    mainNavTitle={mainNavTitle}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavColumn;
