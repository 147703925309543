import React, { useState } from 'react';
import { ModuleBase, Link, ResponsiveImage } from '@/components';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import { isColorDark, themeFromBg } from '@/utils';
import { useTranslation } from 'next-i18next';
import Plus from '@/assets/plus2.svg';
import Minus from '@/assets/minus2.svg';

const FooterVertical = ({ data }) => {
  const { t } = useTranslation('common');
  const [activeIndexList, setActiveIndexList] = useState([]);

  const toggle = (index) => {
    let newArray = [...activeIndexList];

    if (newArray.includes(index)) {
      newArray = newArray.filter((i) => i !== index);
    } else {
      newArray = [index];
    }

    setActiveIndexList(newArray);
  };

  return (
    <ModuleBase
      data={data}
      className="font-cera pt-10 pb-6 md:pb-[33px] lg:pb-10 lg:pt-[136px] md:px-10 bg-primary"
      role="contentinfo"
      aria-label={t('general.$footer')}
      hTag="footer"
    >
      <div className="container md:px-0">
        <div className="main-data-wrapper flex flex-col md:flex-row lg:items-start lg:justify-start md:mb-[178px] lg:pl-[348px]  lg:mb-[122px] text-secondary-coolsand">
          <div className="left-data-wrapper text-center lg:text-left md:w-[32%] lg:w-[230px]">
            <div className="bottom-data text-center md:text-left">
              <p className="mb-6 md:mb-[34px] font-cera text-tag-m not-italic font-medium leading-24">
                {data.address.heading}
              </p>
              <p className="mb-2 font-cera text-tag-m not-italic font-normal leading-24">{data.address.address1}</p>
              <p className="mb-2 font-cera text-tag-m not-italic font-normal leading-24">{data.address.address2}</p>
              <p className="mb-2 font-cera text-tag-m not-italic font-normal leading-24">{data.address.address3}</p>
              <p className="mb-2 font-cera text-tag-m not-italic font-normal leading-24">{data.address.city}</p>
              <p className="font-cera text-tag-m not-italic font-normal leading-24">{data.address.postCode}</p>
            </div>

            {data.contact && (
              <div className="top-data text-center md:text-left mt-12">
                <p className="mb-3 font-cera text-tag-m not-italic font-medium leading-24">
                  {data.contact?.contactTitle}
                </p>

                <a
                  className="mb-2 block font-cera text-tag-m not-italic font-normal leading-24"
                  href={`tel:${data.contact?.phoneNumber}`}
                >
                  {data.contact?.phoneNumber}
                </a>

                <a
                  className="font-cera text-tag-m not-italic font-normal leading-24"
                  href={`mailTo:${data.contact?.emailAddress}`}
                >
                  {data.contact?.emailAddress}
                </a>
              </div>
            )}
          </div>

          {data.linkColumns && (
            <div className="center-data-wrapper md:flex w-full lg:w-[54%] mb-4 md:ml-4 lg:ml-12 mt-6 md:mt-0 flex-col items-center md:items-start md:gap-x-4 md:flex-row md:justify-between">
              {data.linkColumns.map((columnItem, columnIndex) => (
                <div
                  className={classnames(
                    'columns hidden md:block w-full md:w-[44%] lg:w-[32%] text-center md:text-start mb-6  md:mb-0',
                    { 'md:!w-[20%] lg:!w-[32%]': columnIndex === data.linkColumns.length - 1 },
                  )}
                  key={columnIndex}
                >
                  <p className="mb-6 md:mb-[34px] font-cera text-tag-m not-italic font-medium leading-24">
                    {columnItem.columnHeading}
                  </p>
                  {columnItem.links.map((innerColumntItem, innerColumntIndex) => (
                    <div className="inner-column-links mb-2 last:mb-0" key={innerColumntIndex}>
                      <Link link={innerColumntItem} className="font-cera text-tag-m not-italic font-normal leading-24 ">
                        {innerColumntItem.text}
                      </Link>
                    </div>
                  ))}
                </div>
              ))}

              {/* <div className=""> */}
              {data.linkColumns.map((columnItem, columnIndex) => (
                <div
                  className="columns block md:hidden w-full  text-center md:text-start last:mb-0 md:mb-0"
                  key={columnIndex}
                >
                  <div key={columnIndex} className="first:pt-0 first:md:pt-4">
                    <button
                      type="button"
                      className={`relative flex items-center justify-start w-full text-left font-montserrat text-base leading-6.5 pr-[37px]
                  transition-all duration-500 
                  ${activeIndexList.includes(columnIndex) ? 'pb-6 ' : '  pb-6 '}
                  `}
                      onClick={() => toggle(columnIndex)}
                    >
                      <p className="columnHeading  font-cera text-tag-m not-italic font-medium leading-24">
                        {columnItem.columnHeading}
                      </p>
                      <Plus
                        role="presentation"
                        className={classnames(
                          'scale-[0.95] transition-transform duration-200 !stroke-current absolute right-0  -translate-y-1/2',
                          activeIndexList.includes(columnIndex)
                            ? '-rotate-90 opacity-0 top-[45%]'
                            : ' rotate-90 opacity-1 top-[40%]',
                        )}
                      />
                      <Minus
                        role="presentation"
                        className={classnames(
                          'scale-[0.95] transition-transform duration-200 !stroke-current absolute right-0',
                          activeIndexList.includes(columnIndex) ? 'opacity-1 top-[45%]' : '  opacity-0 top-[40%]',
                        )}
                      />
                    </button>

                    <div
                      className={classnames(
                        'transition-all overflow-hidden duration-500 text-left ',
                        activeIndexList.includes(columnIndex)
                          ? `max-h-screen opacity-1  ${!data.allowOpeningMultiple ? 'delay-500' : ''}`
                          : 'max-h-0 pt-0 opacity-50',
                      )}
                    >
                      {columnItem.links.map((innerColumntItem, innerColumntIndex) => (
                        <div className="inner-column-links mb-4 last:mb-6" key={innerColumntIndex}>
                          <Link
                            link={innerColumntItem}
                            className="font-cera text-tag-m not-italic font-normal leading-24 "
                          >
                            {innerColumntItem.text}
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            // </div>
          )}
        </div>

        <div className="bottom-data flex text-secondary-coolsand pt-6 md:pt-[36px] lg:pt-10 border-t border-secondary-coolsand">
          <div className="logo h-fit  aspect-square w-[35px] md:w-[35px] lg:w-[35px]">
            <ResponsiveImage
              image={data.footerLogo}
              widths={{ xs: 35, md: 35, lg: 35, xl: 35 }}
              heights={{ xs: 35, md: 35, lg: 35, xl: 35 }}
              image-class="h-full w-full object-cover "
              className=" h-full w-full object-cover"
            />
          </div>

          <div className="copy-right text-center my-auto ml-auto md:text-start">
            <p className="text-tag-m not-italic font-normal leading-24">{data.copyrightText}</p>
          </div>
        </div>
      </div>
    </ModuleBase>
  );
};

export default FooterVertical;

FooterVertical.propTypes = {
  data: PropTypes.any.isRequired,
};
