/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { ModuleBase, SearchFilters, Pagination, OffersAndEventsFiltering } from '@/components';
import { getSearchFilters } from '@/utils';
import { selectPageId } from '@/store/index';
import GridCard from '@/components/cards/GridCard';
import tailwindConfig from '@/tailwind.config';
import { isColorDark } from '@/utils';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';

const CardListingGridOfferAndEvent = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const router = useRouter();
  const fetchController = useRef(null);
  const queryData = useRef({ filters: [], page: 1 });
  const queryFilterData = useRef({ type: '' });
  const pageId = useSelector(selectPageId);
  const cardContainerRef = useRef(null);
  const [displayedCards, setDisplayedCards] = useState(data.filtersAndCards.pageSize);
  const [loadingMore, setLoadingMore] = useState(false);
  const isDark = isColorDark(data.backgroundColour);
  const { t } = useTranslation('common');

  useEffect(() => {
    let newFilters = { type: String(router.query.type || ''), category: String(router.query.category || '') };
    if (JSON.stringify(newFilters) !== JSON.stringify(queryFilterData.current) && pageId) {
      queryFilterData.current = newFilters;

      const filteredCards = data.filtersAndCards?.cards.filter((card) => {
        if (router.query.type === 'Offers') {
          if (card.props.type === 'Offer') {
            if (router.query.category) {
              return card.props.category === router.query.category;
            }
            return true;
          }
        } else if (router.query.type === 'Events') {
          if (card.props.type === 'Event') {
            return true;
          }
        } else {
          return card;
        }
      });

      setCards(filteredCards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, router.query]);

  const slicedCards = cards.slice(0, displayedCards);

  const setPagedCards = () => {
    setLoadingMore(true);

    setTimeout(() => {
      setDisplayedCards((prev) => prev + data.filtersAndCards.pageSize);
      setLoadingMore(false);
    }, 500); // Adjust timeout duration as needed
  };

  const classByCardsPerRow = (cardsPerRow) => {
    switch (cardsPerRow) {
      case 1:
        return 'xxl:grid-cols-1';
      case 2:
        return 'xxl:grid-cols-2';
      case 3:
        return data.filtersAndCards.cardType === 'Article' ? 'xxl:grid-cols-3' : 'xl:grid-cols-3 xl:w-[1110px]';
      default:
        return 'xxl:grid-cols-3';
    }
  };

  return (
    <>
      {cards && data?.filtersAndCards?.types && (
        <div className="w-full flex justify-between container">
          <OffersAndEventsFiltering
            backgroundColour={data.backgroundColour}
            pageSize={data.filtersAndCards?.pageSize}
            data={data.filtersAndCards}
            slicedCardsLength={slicedCards.length}
            totalCardsLength={cards.length}
            queryMode
            className="mb-[44px] w-full sm:w-fit"
          ></OffersAndEventsFiltering>

          {data.filtersAndCards.showFilters && (
            <div className="showing-wrapper-desktop hidden sm:block pt-4">
              <div
                className={cn(
                  'font-cera text-tag-m not-italic font-normal leading-24',
                  isDark ? 'text-white' : 'text-primary',
                )}
              >
                {t('map.$showingXofX', { showing: slicedCards.length, total: cards.length })}
              </div>
            </div>
          )}
        </div>
      )}
      <div
        className={`container ${data.filtersAndCards.cardType === 'Article' ? 'px-[15.5px] m-auto' : ''}  transition-opacity duration-500 ${loading ? 'opacity-0' : 'opacity-100'}`}
      >
        <div
          className="necessary-classes hidden h-[800px] h-[600px] 
            md:h-[817px] md:h-[617px]
            xxl:h-[774px] xxl:h-[574px]
            xxl:min-h-[873px] xxl:min-h-[637px]
            h-[435px] h-[235px]
            xxl:h-[498px] xxl:h-[298px]
            xxl:h-[597px] xxl:h-[397px]
            xxl:h-[697px] xxl:h-[897px]
            md:h-[764px] md:h-[564px]
            xxl:!h-[700px] xxl:!h-[500px]"
        ></div>

        <div
          ref={cardContainerRef}
          className={`flex flex-col md:grid auto-rows-fr md:auto-rows-auto w-auto md:w-[724px] lg:w-[960px] xl:w-[1200px] xxl:w-[1356px] m-auto grid-cols-1 md:grid-cols-2 ${classByCardsPerRow(data.cardsPerRow)} ${data.filtersAndCards.cardType === 'Article' ? 'gap-4 md:!w-[625px] lg:!w-[932px] xxl:!w-full' : 'gap-10'} xxl:justify-between`}
        >
          {cards &&
            slicedCards.map(
              (card) =>
                card && (
                  <GridCard
                    key={card.moduleId}
                    data={card}
                    cardsPerRow={data.cardsPerRow}
                    cardType={data.filtersAndCards.cardType}
                  />
                ),
            )}
        </div>

        {cards.length > displayedCards && (
          <button
            className={cn(
              'rounded-full mt-10 border block !mx-auto h-[41px] transition cursor-pointer font-cera text-tag-m not-italic font-normal leading-24 px-4 bordertransition',
              isDark ? 'border-secondary-coolsand text-white' : 'border-primary text-primary',
            )}
            onClick={setPagedCards}
          >
            {loadingMore ? 'Loading...' : 'Load More'}
          </button>
        )}
      </div>
    </>
  );
};
export default CardListingGridOfferAndEvent;
