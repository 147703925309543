import React, { useEffect, useState } from 'react';
import { ModuleBase, Link, HeadingTag } from '@/components';
import { useRouter } from 'next/router';
import Arrow from '@/assets/arrowLeft.svg';
import classNames from 'classnames';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';
import { isColorDark } from '@/utils';

const MembershipPortalModule = ({ data }) => {
  const [passwordInput, setNameInput] = useState('');

  const router = useRouter();

  const setCookie = (name, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 10 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()}`;
  };

  const handleSubmit = () => {
    setCookie('membership', passwordInput);

    setTimeout(() => {
      router.reload();
    }, 300);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && passwordInput !== '') {
      event.preventDefault();
      setCookie('membership', passwordInput);
      setTimeout(() => {
        router.reload();
      }, 300);
    }
  };

  useEffect(() => {
    const deleteCookie = (name) => {
      document.cookie = `${name}=; Max-Age=-99999999;`;
    };

    if (data.passwordIsMatch === false) {
      deleteCookie('membership');
      console.log('delete cookie');
    }
  }, [data.passwordIsMatch]);

  return (
    <ModuleBase data={data} className={classNames('h-screen overflow-hidden')}>
      <FadeUpStarter className={classNames('container font-cera color-from-bg h-full px-6')}>
        <div className="flex flex-col h-full justify-center items-center">
          <div className="flex justify-center items-center w-fit pt-10 self-start mb-auto mr-auto">
            <Arrow className="mr-[9px] color-from-bg" />
            <Link link={data.backToLink} className="text-tag-m font-[500] not-italicleading-[22px]" />
          </div>
          <div className="main-wrapper mb-auto md:w-[611px] flex flex-col justify-center text-center">
            <FadeUpAnimation>
              <HeadingTag
                data={{ heading: data.heading, htag: 'H1' }}
                className={`font-ivy-ora mb-6 text-title-s not-italic font-normal leading-32 -tracking-wider lg:text-title-l lg:leading-60 lg:-tracking-normal
                    md:text-title-m  md:leading-headingReg md:-tracking-paragraphReg md:mb-12`}
              />
            </FadeUpAnimation>

            <FadeUpAnimation>
              <p className="text-paragraph-normal not-italic font-normal leading-24">{data.passwordLabel}</p>
            </FadeUpAnimation>

            <FadeUpAnimation>
              <form className="w-full md:w-[547px] mx-auto">
                <input
                  name="name"
                  placeholder="Enter password"
                  type="password"
                  id="name"
                  className="text-primary px-2 rounded-[2px] h-10 mt-4 mb-[50px] w-full focus:outline-none"
                  onChange={(event) => setNameInput(btoa(event.target.value))}
                  onKeyDown={handleKeyDown}
                />
              </form>
            </FadeUpAnimation>
            <FadeUpAnimation>
              <button
                disabled={passwordInput === ''}
                className={classNames(
                  'mb-4 hover:bg-highlight-sunshineYellow cursor-pointer transition-all duration-500 text-tag-m not-italic font-medium leading-[22px] h-[44px] px-10',
                  isColorDark(data.backgroundColour) ? 'bg-white/10' : 'bg-black/10',
                )}
                onClick={handleSubmit}
              >
                Sign in
              </button>

              {data.passwordIsMatch === false && (
                <p className="text-error text-tag-m not-italic font-normal leading-[22px]">
                  {data?.incorrectPasswordMessage}
                </p>
              )}
            </FadeUpAnimation>
            <FadeUpAnimation>
              <span className="text-tag-m color-from-bg not-italic font-normal leading-[22px] mr-2">
                {data.joinLink.text}
              </span>
              <Link link={data.joinLink.link} className="underline text-tag-m not-italic font-normal leading-[22px]" />
            </FadeUpAnimation>
          </div>
        </div>
      </FadeUpStarter>
    </ModuleBase>
  );
};

export default MembershipPortalModule;
